<template>
    <div v-if="schedules.length <= 0 && schedulesHistory.length <= 0">
        <img :src="require('@/assets/images/save_to_bookmarks.svg')"
             alt="Doctor" class="rounded img-fluid mx-auto d-block w-50">
        <br>

        <p class="text-center text-muted para-desc mx-auto">
      <span class="text-dark">
        Você ainda não possui nenhuma consulta marcada
      </span>
            <br>
            Marque agora mesmo uma consulta, não deixe sua saúde para depois.
        </p>

        <div class="text-center">
            <router-link to="/" class="btn btn-primary">
                Encontre um profissional
            </router-link>
        </div>
    </div>

    <div v-else>
        <div class="row">
            <div class="col-lg-12">
                <ul class="nav nav-pills nav-justified flex-column flex-sm-row rounded" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link rounded active" id="pills-cloud-tab" data-bs-toggle="pill"
                           href="#pills-next" role="tab" aria-controls="pills-cloud" aria-selected="true">
                            <div class="text-center py-2">
                                <h6 class="mb-0">Próximas Consultas</h6>
                            </div>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link rounded border mt-2 mt-md-0 ms-md-2" id="pills-smart-tab"
                           data-bs-toggle="pill"
                           href="#pills-histories" role="tab" aria-controls="pills-smart" aria-selected="false">
                            <div class="text-center py-2">
                                <h6 class="mb-0">Histórico de Consultas</h6>
                            </div>
                        </a>
                    </li>

                </ul>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-12">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade active show" id="pills-next" role="tabpanel"
                         aria-labelledby="pills-cloud-tab">

                        <div v-if="schedules.length <= 0">
                            <img :src="require('@/assets/images/save_to_bookmarks.svg')"
                                 alt="Doctor" class="rounded img-fluid mx-auto d-block w-50">
                            <br>
                            <p class="text-center text-muted para-desc mx-auto">
                  <span class="text-dark">
                    Você não possui consultas agendadas.
                  </span>
                                <br>
                                Marque agora mesmo uma consulta, não deixe sua saúde para depois.
                            </p>

                            <div class="text-center">
                                <router-link to="/" class="btn btn-primary">
                                    Encontre um profissional
                                </router-link>
                            </div>
                        </div>

                        <div v-else>
                            <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate"
                                 v-for="schedule in schedules">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-2 text-center text-md-start">

                                                    <img v-if="schedule.prof_photo"
                                                         :src="url(schedule.prof_photo)"
                                                         class="avatar avatar-md-md shadow-md rounded-circle"
                                                         alt="Foto do profissional">

                                                    <img v-else src="../../assets/images/no-photo.png"
                                                         class="avatar avatar-md-md shadow-md rounded-circle"
                                                         alt="Foto do profissional">

                                                </div>
                                                <div class="col-md-5 text-start">
                                                    <p class="text-dark text-center text-md-start">
                                                        {{ schedule.prof_name }} <br>
                                                        <span class="badge bg-primary">{{ schedule.spec_name }}</span>
                                                    </p>
                                                    <p class="text-dark">
                                                        <b>Local: </b> <br>
                                                        <span class="text-dark">{{ schedule.place_name }}</span> <br>
                                                        <b>Endereço: </b> <br>
                                                        {{ schedule.place_address }}
                                                    </p>
                                                    <p class="text-dark">
                                                        <b>Paciente: </b> <br>
                                                        {{ schedule.patient_name }} <br>
                                                        <span v-if="schedule.avail_online" class="badge bg-primary"> Online </span>
                                                    </p>
                                                </div>
                                                <div class="col-md-5 text-start">
                                                    <p class="text-dark">
                                                        <b>Data e Hora: </b> <br>
                                                        {{ date(schedule.sched_datetime_patient).format('DD/MM/YYYY HH:mm') }}
                                                        <br>

                                                        <span v-if="schedule.sched_value > 0">
                                                          <b>Valor: </b> <br>
                                                          R$ {{schedule.sched_value.toLocaleString('pt-br', {
                                                                    style: 'decimal',
                                                                    minimumFractionDigits: 2,
                                                                }) ?? '0'
                                                            }} <br>
                                                        </span>

                                                        <b>Forma de pagamento: </b>
                                                        <br>
                                                        {{ schedule.paymeth_name }} <br>

                                                        <b>Status: </b>
                                                        <br>
                                                        {{ schedule.status }} <br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 text-center">
                                            <p class="alert-danger rounded h4" v-if="schedule.sched_status === 'CA'">
                                                Consulta Cancelada</p>

                                            <button type="button" @click="showPixData(schedule)"
                                                    v-if="schedule.sched_status === 'AP' && schedule.paymeth_type === 'PI'"
                                                    class="btn btn-success me-1 mt-1 mb-1">
                                              Realizar pagamento
                                            </button>
                                            <button type="button" @click="rejectPayment(schedule)"
                                                      v-if="schedule.sched_status === 'AP' && schedule.paymeth_type === 'PI'"
                                                      class="btn btn-warning me-1 mt-1 mb-1">
                                                Desistir e Liberar horário
                                            </button>

                                            <button type="button" @click="cancelSchedule(schedule)"
                                                    v-if="schedule.allow_cancel"
                                                    class="btn btn-danger me-1 mt-1 mb-1">
                                                Cancelar Consulta
                                            </button>
                                            <button type="button" @click="onClickToReSchedule(schedule)"
                                                    class="btn btn-info me-1 mt-1 mb-1"
                                                    v-if="schedule.allow_reschedule">
                                                Remarcar Consulta
                                            </button>
                                            <button type="button" @click="openModalScheduleReturn(schedule)"
                                                    class="btn btn-secondary me-1 mt-1 mb-1"
                                                    v-if="schedule.allow_return">
                                                Agendar Retorno
                                            </button>
                                            <button type="button" @click="startAttendanceOnline(schedule)"
                                                    class="btn btn-secondary me-1 mt-1 mb-1"
                                                    v-if="schedule.allow_entry_queue">
                                                Atendimento online
                                            </button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="pills-histories" role="tabpanel" aria-labelledby="pills-smart-tab">
                        <div v-if="schedulesHistory.length <= 0">
                            <img :src="require('@/assets/images/save_to_bookmarks.svg')"
                                 alt="Doctor" class="rounded img-fluid mx-auto d-block w-50">
                            <br>
                            <p class="text-center text-muted para-desc mx-auto">
                                  <span class="text-dark">
                                    Você ainda não possui nenhuma consulta no histórico
                                  </span>
                                <br>
                            </p>
                        </div>

                        <div v-else>
                            <div class="card shadow rounded border-0 mb-3 pricing-rates business-rate"
                                 v-for="schedule in schedulesHistory">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-2 text-center text-md-start">

                                                    <img v-if="schedule.prof_photo"
                                                         :src="url(schedule.prof_photo)"
                                                         class="avatar avatar-md-md shadow-md rounded-circle"
                                                         alt="Foto do profissional">

                                                    <img v-else src="../../assets/images/no-photo.png"
                                                         class="avatar avatar-md-md shadow-md rounded-circle"
                                                         alt="Foto do profissional">

                                                </div>
                                                <div class="col-md-5 text-start">
                                                    <p class="text-dark text-center text-md-start">
                                                        {{ schedule.prof_name }} <br>
                                                        <span class="badge bg-primary">{{ schedule.spec_name }}</span>
                                                    </p>
                                                    <p class="text-dark">
                                                        <b>Local: </b> <br>
                                                        <span class="text-dark">{{ schedule.place_name }}</span> <br>
                                                        <b>Endereço: </b> <br>
                                                        {{ schedule.place_address }}
                                                    </p>
                                                </div>
                                                <div class="col-md-5 text-start">
                                                    <p class="text-dark">
                                                        <b>Data e Hora: </b> <br>
                                                        {{ date(schedule.sched_datetime_patient).format('DD/MM/YYYY HH:mm') }}
                                                        <br>
                                                        <br>

                                                        <span v-if="schedule.sched_value > 0">
                                                            <b>Valor: </b>
                                                            <br>
                                                            R$ {{schedule.sched_value.toLocaleString('pt-br', {
                                                                    style: 'decimal',
                                                                    minimumFractionDigits: 2
                                                                }) ?? '0'
                                                            }} <br>
                                                        </span>

                                                        <b>Forma de pagamento: </b>
                                                        <br>
                                                        {{ schedule.paymeth_name }} <br>

                                                        <b>Status: </b>
                                                        <br>
                                                        {{ schedule.status }} <br>

                                                        <b>Paciente: </b> <br>
                                                        {{ schedule.patient_name }} <br>
                                                        <span v-if="schedule.avail_online" class="badge bg-primary"> Online </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-12 text-center">
                                                <p class="alert-danger rounded h4"
                                                   v-if="schedule.sched_status === 'CA'">Consulta Cancelada</p>
                                                <button type="button" @click="onClickToReSchedule(schedule)"
                                                        class="btn btn-info me-1 mt-1 mb-1"
                                                        v-if="schedule.allow_reschedule">
                                                    Remarcar Consulta
                                                </button>
                                                <button type="button" @click="openModalScheduleReturn(schedule)"
                                                        class="btn btn-secondary me-1 mt-1 mb-1"
                                                        v-if="schedule.allow_return">
                                                    Agendar Retorno
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" ref="modal_availability_prof" id="modal_availability_prof" tabindex="1">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Escolha um dia e horário</h5>
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                         data-bs-dismiss="modal" aria-label="Fechar">
                        <i class="bi bi-x-lg"></i>
                    </div>
                </div>
                <div class="modal-body">
                    <Times v-if="currentSchedule != null"
                           :id_prof="currentSchedule.id_prof"
                           :id_place="currentSchedule.id_place"
                           :id_spec="currentSchedule.id_spec"
                           :id_city="currentSchedule.sched_online ? null : currentSchedule.id_city"
                           :id_insurance="currentSchedule.id_insurance"
                           :id_paymeth="currentSchedule.id_paymeth"
                           :id_sched_father="currentSchedule.id_sched"
                           :professional="professional"
                           @onClickDate="selectAvailability"
                    />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import http from "@/helpers/http";
import date from "@/helpers/date";
import Alert from "@/helpers/alert";
import Swal from "sweetalert2";
import Times from "../components/Times";
import str from "../../helpers/str";

export default {
    name: "Schedules",
    components: {
        Times,
    },
    data() {
        return {
            schedules: [],
            schedulesHistory: [],
            professional: {},
            currentSchedule: null,
            currentScheduleType: null,
        }
    },
    computed: {
        userAuth() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        this.loadSchedules();
        this.loadSchedulesHistory();
    },
    methods: {
        date: date,
        url(path) {
            return str.url(path)
        },
        loadSchedules() {
            return http.get(`/users/${this.userAuth.id_user}/schedules`).then((response) => {
                this.schedules = response.data;
            });
        },
        loadSchedulesHistory() {
            http.get(`/users/${this.userAuth.id_user}/schedules/history`).then((response) => {
                this.schedulesHistory = response.data;
            });
        },
        startAttendanceOnline(schedule) {
            Swal.fire({
                title: 'Atenção',
                text: "Tem certeza que deseja iniciar o atendimento online?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#780f5a',
                cancelButtonColor: '#ccc',
                confirmButtonText: 'Sim, Entrar na fila!',
                cancelButtonText: "Não"
            }).then((result) => {
                if (result.isConfirmed) {
                    if (schedule.started_at != null && schedule.finished_at == null) {
                        this.$router.push(`/schedules/${this.scheduleId}/attendance`);
                        return;
                    }

                    http.post(`/users/${this.userAuth.id_user}/schedules/${schedule.id_sched}/queues/entry`).then((response) => {
                        this.$router.push(`/schedules/${schedule.id_sched}/queues/${response.data.id_queue}`);
                        return response;
                    }).catch((e) => {
                        console.log(e);
                        let response = e.response?.data;
                        Alert.error(response);
                    });
                }
            });
        },
        cancelSchedule(schedule) {
            Swal.fire({
                title: 'Cancelar',
                text: "Tem certeza que deseja cancelar essa consulta?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#780f5a',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, Cancelar!',
                cancelButtonText: "Não"
            }).then((result) => {
                if (result.isConfirmed) {
                    http.patch(`/users/${this.userAuth.id_user}/schedules/${schedule.id_sched}/cancel`).then(() => {
                        this.loadSchedules();
                        Alert.success('Cancelamento realizado com sucesso.');
                    }).catch((e) => Alert.error(e.response.data));
                }
            })
        },
        onClickToReSchedule(schedule) {
            this.professional = {};

            let accessToken = this.$store.state.auth.token;
            let combinedString = `${accessToken}@${this.userAuth.id_user}@${schedule.id_sched}`;
            let base64String = btoa(combinedString);

            this.$router.push({ path: '/schedules/reschedule', query: { params: base64String } })
        },
        rejectPayment(schedule) {
          Swal.fire({
            title: 'Cancelar pagamento',
            text: "Tem certeza que deseja cancelar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, cancelar!',
            cancelButtonText: "Não, Realizar pagamento"
          }).then((result) => {
            if (result.isConfirmed) {
              http.patch(`/users/${this.userAuth.id_user}/schedules/${schedule.id_sched}/reject-payment`).then(() => {
                this.loadSchedules();
              });
            }
          });
        },
        showPixData(schedule) {
          http.get(`/users/${this.userAuth.id_user}/schedules/${schedule.id_sched}/payment-data`).then((response) => {
            this.$store.commit('toSchedule/setPixData', response.data);
            this.$router.push('/pix');
          });
        },
        openModalScheduleReturn(schedule) {
            this.professional = {};
            this.currentSchedule = schedule;
            this.currentScheduleType = 'SCHEDULE_RETURN';

            let params = {
                id_place: schedule.id_place,
                id_city: schedule.avail_online ? null : schedule.id_city,
                id_spec: schedule.id_spec,
                id_paymeth: schedule.id_paymeth,
                id_insurance: schedule.id_insurance,
                with_availability: true,
                start_end_days: this.startEndDays,
                id_prof: schedule.id_prof,
                is_return: 'S',
            };

            http.get(`/schedule/professionals/${schedule.id_prof}`, {params: params}).then((response) => {
                this.professional = response.data;
                let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_availability_prof'));
                modal.toggle();
            });
        },
        returnSchedule(availabilityTime) {
            let param = {
                sched_date: availabilityTime.available_date,
                sched_time: availabilityTime.available_time,
                sched_datetime_patient: availabilityTime.sched_datetime_patient,
            };

            http.post(`/users/${this.userAuth.id_user}/schedules/${this.currentSchedule.id_sched}/return`, param).then(async () => {
                await this.loadSchedules();

                let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_availability_prof'));
                modal.toggle();

                Alert.success('Retorno agendado com sucesso.');
            }).catch((e) => Alert.error(e.response.data));
        },
        reSchedule(availabilityTime) {
            let param = {
                sched_date: availabilityTime.available_date,
                sched_time: availabilityTime.available_time,
                sched_datetime_patient: availabilityTime.sched_datetime_patient,
            };

            http.post(`/users/${this.userAuth.id_user}/schedules/${this.currentSchedule.id_sched}/reschedule`, param).then(async () => {
                await this.loadSchedules();

                let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_availability_prof'));
                modal.toggle();

                Alert.success('Reagendamento realizado com sucesso.');
            }).catch((e) => Alert.error(e.response.data));
        },
        selectAvailability(availabilityTime) {
            if (this.currentScheduleType === 'RESCHEDULE') {
                this.reSchedule(availabilityTime);
            } else {
                this.returnSchedule(availabilityTime);
            }
        },
    }
}
</script>

<style scoped>

</style>
