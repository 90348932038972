<template>

</template>

<script>
import http from "../../helpers/http";
import Swal from "sweetalert2";

export default {
  name: 'RememberPayment',
  data() {
    return {
      scheduleId: null,
      tokenSession: null,
    }
  },
  mounted() {
    Swal.fire({
      title: 'Por favor, aguarde!',
      text: 'Carregando informações',
    });
    Swal.showLoading();

    this.tokenSession = this.$route.query.token;
    this.scheduleId = this.$route.params.id;
    this.authUsingToken().then(() => {
      this.showPixData();
    });
  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    authUsingToken() {
      return this.$store.dispatch('auth/loginUsingToken', this.tokenSession)
        .catch(() => {
          Swal.fire({
            title: 'Opsss!',
            text: 'Falha ao fazer login',
            icon: 'error',
            allowOutsideClick: false,
            confirmButtonText: 'Acessar minha conta',
            confirmButtonColor: '#780f5a',
          }).then(() => {
            this.$router.push('/schedules');
          })
        });
    },
    showPixData() {
      http.get(`/users/${this.userAuth.id_user}/schedules/${this.scheduleId}/payment-data`).then((response) => {
        this.$store.commit('toSchedule/setPixData', response.data);
        Swal.close();
        this.$router.push('/pix');
      }).catch(() => {
        Swal.fire({
          title: 'Opsss!',
          text: 'Falha ao carregar informações',
          icon: 'error',
          allowOutsideClick: false,
          confirmButtonText: 'Acessar minha conta',
          confirmButtonColor: '#780f5a',
        }).then(() => {
          this.$router.push('/schedules');
        })
      });
    },
  }
}
</script>

<style scoped>

</style>
